import React from 'react';
import './headerwrap.css'
import MyNavLink from './my-nav-link'
import logo from '../../content/assets/cuge.png'

export default class Headerwrap extends React.Component{
    state={
        showheader:true
    }

    componentDidMount() {
        let width=document.body.clientWidth
        console.log(width)
        this.setState(
            {
                showheader:width>820?true:false
            }
        )
    }

    showheader=()=>{
        console.log("showheader",this.state.showheader)
        this.setState({
            showheader:true
        })
    }
    hideHeader=()=>{
        this.setState({
            showheader:false
        })
    }
    render() {
        return(
            <div id="headerwrap" className="fixed-header">
                <header id="header" className="pagewidth">
                    <MyNavLink id="cugefit-logo" to="/">
                        <img src={logo} alt="logo"/>
                        <div>赛事网站定制</div>
                    </MyNavLink>
                    <span id="menu-icon" href="#sidr" onClick={this.showheader}></span>
                    <nav id="sidr" className="sidr right_header phonedisplay" style={{display:this.state.showheader?'block':'none'}}>
                        <ul id="main-nav" className="clearfix main-nav">
                            <li id="menu-item-7034"
                                className="menu-item menu-item-type-post_type menu-item-object-page current-menu-ancestor current-menu-parent current_page_parent current_page_ancestor menu-item-has-children menu-item-7034">
                                <MyNavLink to="/">模板</MyNavLink>
                                <ul className="sub-menu">
                                    <li id="menu-item-7040"
                                        className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item menu-item-7040">
                                        <MyNavLink to="/">所有模板</MyNavLink></li>
                                    <li id="menu-item-7040"
                                        className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item menu-item-7040">
                                        <MyNavLink to="/race_themes">赛事模板</MyNavLink></li>
                                    <li id="menu-item-7040"
                                        className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item menu-item-7040">
                                        <MyNavLink to="/org_themes">机构模板</MyNavLink></li>
                                </ul>
                            </li>
                            <li id="menu-item-7034"
                                className="menu-item menu-item-type-post_type menu-item-object-page current-menu-ancestor current-menu-parent current_page_parent current_page_ancestor menu-item-has-children menu-item-7034">
                                <MyNavLink to="/all_plugins">插件</MyNavLink>
                                <ul className="sub-menu">
                                    <li id="menu-item-7040"
                                        className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item menu-item-7040">
                                        <MyNavLink to="/all_plugins">全部插件</MyNavLink></li>
                                    <li id="menu-item-7041"
                                        className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item menu-item-7040">
                                        <MyNavLink to="/hearder_poster">页头图</MyNavLink></li>
                                    <li id="menu-item-7042"
                                        className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item menu-item-7040">
                                        <MyNavLink to="/fast_entry">快速入口</MyNavLink></li>
                                    <li id="menu-item-7043"
                                        className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item menu-item-7040">
                                        <MyNavLink to="/countdown">倒计时</MyNavLink></li>
                                    <li id="menu-item-7044"
                                        className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item menu-item-7040">
                                        <MyNavLink to="/news">新闻公告</MyNavLink></li>
                                    <li id="menu-item-7045"
                                        className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item menu-item-7040">
                                        <MyNavLink to="/map">地图</MyNavLink></li>
                                    <li id="menu-item-7046"
                                        className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item menu-item-7040">
                                        <MyNavLink to="/images">赛事照片</MyNavLink></li>
                                    <li id="menu-item-7047"
                                        className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item menu-item-7040">
                                        <MyNavLink to="/thanks">赞助商列表</MyNavLink></li>
                                    <li id="menu-item-7048"
                                        className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item menu-item-7040">
                                        <MyNavLink to="/footer_poster">页尾图</MyNavLink></li>
                                </ul>
                            </li>
                            <li id="menu-item-7029"
                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7029"><MyNavLink
                                to="/contact">联系我们</MyNavLink></li>
                        </ul>
                        <a id="menu-icon-close" href="#sidr" onClick={this.hideHeader}></a>
                    </nav>
                </header>
            </div>
        )
    }
}