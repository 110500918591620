import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

export function SEO(title) {
  return (
    <Helmet>
      <title>赛事网站定制</title>
      <link rel="icon" href="../../content/assets/favicon.ico" />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
}
