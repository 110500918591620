import React from "react";
// import logo from '../a2/logo.png'
import './footerwrap.css'

export default class Footerwrap extends React.Component{
    render() {
        return(
            <div id="footerwrap" className="black animated-bg stopsticky">
                <footer id="footer" className="pagewidth">

                    <div className="back-top clearfix">
                        <div className="arrow-up">
                            <a href="#" ></a>
                            <span>Back to Top</span>
                        </div>
                    </div>

                    <div className="footer-content clearfix">
                        {/*<div className="footer-logo"><a  href="http://cugefit.com"><img*/}
                        {/*    src={logo}*/}
                        {/*    alt="cugefit logo" /></a></div>*/}
                        <div style={{fontWeight:"bold",color:"white",marginBottom:"30px",fontSize:"1.3rem"}}>用科技提高赛事品质&nbsp;&nbsp;&nbsp;&nbsp;用科技改善跑者服务</div>
                        <div className="contact-us">
                            <a href="/aboutUs" style={{marginRight:"8%"}}><img alt="关于我们" src={require("../images/aboutus.png")}/><div>关于我们</div></a>
                            <a href="/contact"><img alt="联系我们" src={require("../images/contactus.png")}/><div>联系我们</div></a>
                        </div>
                        <p><a href="/aboutUs">©簇格软件 2020</a> | 苏州簇格软件有限公司版权所有
                            <a href="http://www.beian.miit.gov.cn/" style={{marginLeft:"16px"}}>苏ICP备14009355号-3</a>
                        </p>
                    </div>

                    {/*<div className="footer-social-links clearfix">*/}
                    {/*    <ul className="social-links-horizontal">*/}
                    {/*        <li className="social-links-item"></li>*/}
                    {/*        <li className="social-links-item"></li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}

                    {/*<div className="footer-menu-wrapper clearfix">*/}

                    {/*    <div className="products">*/}
                    {/*        <h6 className="menu-title">Products</h6>*/}
                    {/*        <ul>*/}
                    {/*            <li><a href="https://themify.me/themes">Themes</a></li>*/}
                    {/*        </ul>*/}
                    {/*    </div>*/}

                    {/*    <div className="info">*/}
                    {/*        <h6 className="menu-title">Info</h6>*/}
                    {/*        <ul>*/}
                    {/*            <li><a href="https://themify.me/pricing">Pricing</a></li>*/}
                    {/*        </ul>*/}
                    {/*    </div>*/}

                    {/*    <div className="support">*/}
                    {/*        <h6 className="menu-title">Support</h6>*/}
                    {/*        <ul>*/}
                    {/*            <li><a href="https://themify.me/docs">Documentation</a></li>*/}
                    {/*        </ul>*/}
                    {/*    </div>*/}

                    {/*</div>*/}

                </footer>
            </div>
        )
    }
}